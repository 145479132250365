* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-check-input {
  position: relative;
  margin: 0;
  padding: 0;
}

/* colors */
.primary-color {
  color: #0b3b5d !important;
}
.primary-button {
  background-color: #0b3b5d !important;
  color: white !important;
}
.success-button {
  background-color: green !important;
  color: white !important;
}

.bg-grey {
  background-color: #f4f4f9;
}

.color-grey {
  color: lightgray;
}

.secondary-color {
  color: orange;
}

.setup-bg-color {
  background-color: #0b3b5d;
  color: #fff !important;
  transition: background-color 0.3s ease;
  border: none;
  font-weight: 500;
}

.setup-bg-color:hover {
  background-color: #072335; /* Darker shade on hover */
}

.setup-bg-color:active {
  background-color: #04151d !important; /* Even darker shade on click */
}

.red-shade {
  color: #8b0000;
}

.green-color {
  color: #a2c579;
}

.background-green {
  background-color: #a2c579;
  color: white;
}
/* special */

.border-none {
  border: none;
}

.max-content {
  width: max-content;
}

.cursor-pointer {
  cursor: pointer;
}

.nonestyle {
  list-style: none;
}
.no-wrap {
  white-space: nowrap;
}

.hrStyle {
  width: 60%;
  margin: "0 auto";
  border-top: "1px solid #000";
}

hr {
  background-color: grey; /* You can adjust the border style and color as needed */
}

.custom-checkbox {
  width: 30px;
  height: 30px;
}

/* background styles */

.bg-button-secondary {
  background-color: #a2c579;
  color: white;
}

.vertical-line {
  border-left: 1px solid #000; /* Change the color or style as needed */
  height: 100%; /* Adjust the height as needed */
}

/* //all fonts ===
===============
=============== */
.font1 {
  font-family: "Raleway", Sans-serif;
}
.w-70 {
  width: 40vw;
}

.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}

.small-font {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-medium {
  font-size: 15px;
}

.small-icon {
  height: 14px;
  width: auto;
}
.icon-size {
  height: 2vw;
  width: auto;
}
.icon-size-2 {
  height: 24px;
  width: auto;
}

.text-light {
  color: white !important;
}

.text-spacing {
  letter-spacing: 0.5rem;
}
.text-primary {
  color: #0b3b5d !important;
}

.navbarStyle {
  color: white;
  background-color: #0b3b5d;
}

/* navbar css */

.navbar {
  padding: 0;
}

/* home page */

/* ==================
==================
DashBoard CSS==========
======================
================= */

.width-for-content {
  padding: 0vw 20vw;
}
.main-width {
  width: 70vw;
}

.half-width {
  width: 30vw;
}

.slider-width {
  width: 29vw;
}

.sidebar-width {
  width: 15vw;
}

.Layout2-width {
  width: 54vw;
}

.dashbaordBody li {
  align-items: baseline;
  justify-content: space-between;
}

.element1 {
  display: flex;
  justify-content: space-between;
}

.QuickLinks {
  display: flex;
  justify-content: space-around;
}

.QuickLinksitem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 
footer=====
==========
========== */
.footer-links li {
  font-size: smaller;
  padding: 0.3rem 0rem;
}

/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}

/* for desktop view======
========================
======================= */

@media (max-width: 1024px) {
  .main-width {
    width: 90vw;
  }
  .icon-size {
    height: 3vw;
    width: auto;
  }
  .slider-width {
    width: 39.5vw;
  }
  .sidebar-width {
    width: 25vw;
  }
  .Layout2-width {
    width: 64vw;
  }
}

/* 
//testing----------------------
=============================== */
.view-emp-style {
  position: relative;
}

.view-emp-id-row th,
.view-emp-id-row td {
  vertical-align: middle;
  text-align: center;
}

.view-emp-h2 th {
  vertical-align: middle;
}

/* ------------All Register File css ------------------------------
----------------------------------------------------------------
---------------------------------------------------------------- */
.log1-body {
  padding: 0rem 15rem;
}

.reg1-email {
  gap: 20;
}

.ml-1 {
  margin-left: 1rem;
}

.bg-color-2 {
  background-color: #eb9b00;
  color: white;
  border: none;
}

.container-form {
  padding: 1rem 4rem;
  margin-top: 1rem;
  background-color: #0b3b5d;
  color: white;
}

.update-review-input {
  border-radius: 0.2rem;
  font-weight: 600;
  border: none;
  font-size: small;
  padding: 0.5rem 2rem;
}

.container-button {
  display: flex;
}

.container-button span {
  font-size: small;
}

/* //register-3=====
================
================ */

.custom-width {
  width: 100%;
}

.bg-color {
  background-color: #0b3b5d;
  color: white;
}

.bg-color:disabled {
  opacity: 0.2;
}

.text-primary:hover {
  color: red;
  cursor: pointer;
}

.btn.bg-color {
  pointer-events: none;
}

/* //changing bootgstrap classes */
.verification-tabs .nav-link {
  color: #fff;
  background-color: lightgray;
}

.verification-tabs .nav-link.active {
  background-color: #0b3b5d;
  color: #fff;
}

/* mobile  view  */
@media (max-width: 944px) {
  .mobileViews {
    display: none !important;
  }

  .Layout2-width {
    width: 100%;
    max-width: 100%;
  }
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}
