@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #fff;
  background-color: #20533a;
}

.desktop-headeraccount {
  display: block;
}

.mobile-headeraccount {
  display: none;
}

.header-brand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobile-nav__menu-button {
  display: none;
}

.nav-area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 14px;
}

.desktop-nav .menu-items a {
  font-family: "Raleway", sans-serif;
  display: block;
  font-size: 15px;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: #20533a;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items > a,
.desktop-nav .menu-items button {
  text-align: left;
  background-color: #20533a;
  padding: 1rem 0.8rem;
}

.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  background-color: #20533a;
}

/* .desktop-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  } */

.desktop-nav .dropdown {
  position: absolute;
  left: auto;
  top: 53px;
  font-family: "Raleway", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  width: 260px;
  z-index: 9999;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: 0px;
}

.mobile-nav {
  display: none;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .desktop-headeraccount {
    display: none;
  }

  .mobile-headeraccount {
    display: block;
  }

  .header-brand {
    width: 80%;
    margin-left: 20px;
    margin-block: 5px;
  }

  .mobile-nav {
    display: flex !important;
    width: 100%;
    justify-content: space-between;
  }

  .mobile-nav__menu-button {
    display: block;
  }

  .nav-area {
    justify-content: space-between;
    padding: 13px 30px;
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
  }
  .mobile-nav .mobile-nav__menu-button {
    color: #fff;
    font-size: 18px;
    border: none;
    background-color: #20533a;
    cursor: pointer;
    position: relative;
  }
  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    font-size: 12px;
    top: 90px;
    left: 0px;
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #20533a;
    width: 100%;
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: #20533a;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-color: #20533a;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 14px;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }
}
.show .menu-items button{
  padding-block: 0px;
  background-color: #20533a;
}

.show .menu-items a{
  /* font-family: "Raleway", Sans-serif; */
  font-size: 14px;
  font-weight: 400;
  padding-block: 10px;
  color: #fff;
}